.content {
  padding: var(--dj-size-spacing-xl) var(--dj-size-spacing-2xl);
  display: flex;
  justify-content: center;
}

.form {
  display: flex;
}

.history-item {
  border: 1px solid var(--dj-color-foreground);
  padding: var(--dj-size-spacing-xs) var(--dj-size-spacing-sm);
  margin-top: var(--dj-size-spacing-xs);
  cursor: pointer;
}
.history-item.error {
  border: 1px solid var(--dj-color-danger);
}
